import React from 'react';
import {Create, Datagrid, Edit, List, SimpleForm, TextField, TextInput} from 'react-admin';

export const CountryList = (props) => (
    <List {...props} pagination={<div></div>}>
        <Datagrid>
            <TextField label="#" source="order" />
            <TextField label="Country" source="name" />
            <TextField label="Users" source="userCount" />
            <TextField label="Language practice" source="readyUserCount" />
        </Datagrid>
    </List>
);

export const CountryEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput label="ISO 639-1" source="iso2" />
            <TextInput label="ISO 639-2 Т" source="iso3" />
        </SimpleForm>
    </Edit>
);

export const CountryCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
			<TextInput source="name" />
            <TextInput label="ISO 639-1" source="iso2" />
            <TextInput label="ISO 639-2 Т" source="iso3" />
        </SimpleForm>
    </Create>
);


const centerCell = {
    textAlign: "center",
    width: "100%",
    display: "inline-block"
};
