import React, { useEffect, useState } from "react";
import {
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  SaveButton,
  Filter,
  FunctionField,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  maxValue,
  DatagridLoading,
  Loading,
  Error,
} from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import {
  centerText,
  dataGrid,
  rightElement,
  setWidth,
  shortLink,
  smallButton,
} from "./helper";
import { ConditionalBulkDeleteButton } from "./ConditionalBulkDeleteButton";
import { ConditionalDeleteButton } from "./ConditionalDeleteButton";
import axios from "axios";

const validateUser = (values) => {
  const errors = {};
  if (values.premium && !values.premiumExp) {
    errors.premiumExp = ["The premiumExp is required."];
  }
  return errors;
};

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by email" source="email" alwaysOn />
    <TextInput label="Search by id" source="id" alwaysOn />
    {/*<ReferenceInput label="UserID" source="id" reference="users" allowEmpty>*/}
    {/*    <SelectInput optionText="id"/>*/}
    {/*</ReferenceInput>*/}
  </Filter>
);

const createType = (type, briefly, name) => ({ type, briefly, name });
const defaultListTypes = [
  createType(null, "F", "Free"),
  createType("FREE", "F", "Free"),
  createType("USER_PREMIUM", "U", "User"),
  createType("TEACHER", "T", "Teacher"),
  createType("SCHOOL", "S", "School"),
  createType("LARGE_SCHOOL", "LS", "Large School"),
];
const findType = (type) => {
  const searchType = defaultListTypes.find((data) => data.type === type);
  return searchType ? searchType : findType(null);
};

const SubscriptionsType = ({ record }) => {
  const subscriptions = record?.subscriptions ?? [];

  const listTypes = subscriptions.map((subscription, index) => {
    const parseType = findType(subscription.type);
    if (parseType.name === 'Free') return null
    return (
      <span style={{ color: "red", fontWeight: "bold" }}>
        {parseType.briefly} {subscriptions.length !== index + 1 ? " & " : null}
      </span>
    );
  });

  if (!record?.premium) return null

  if (subscriptions.length) {
    return (
      <span
        style={{
          display: "inline-block",
          marginLeft: "auto",
        }}
      >
        {listTypes} {new Date(record?.premiumExp).toLocaleString()}
      </span>
    );
  }

  if (record.premiumExp) {
    const subscriptionType = findType('USER_PREMIUM')
    return (
      <span
        style={{
          display: "inline-block",
          marginLeft: "auto",
        }}
      >
        <span style={{ color: 'red', fontWeight: 'bold' }}>{subscriptionType.briefly}</span> {new Date(record?.premiumExp).toLocaleString()}
      </span>
    )
  }

  if (!subscriptions.length) {
    return null
  }
};
SubscriptionsType.defaultProps = { subscriptions: [] };

export const UserList = (props) => (
  <List
    filters={<UserFilter />}
    perPage={100}
    sort={{ field: "createdAt", order: "DESC" }}
    {...props}
    bulkActionButtons={<ConditionalBulkDeleteButton />}
  >
    <Datagrid style={dataGrid}>
      <TextField
        style={setWidth("auto")}
        label="#"
        source="order"
        sortable={false}
      />
      <TextField style={setWidth(120)} label="First Name" source="firstName" />
      <TextField style={setWidth(150)} label="Last Name" source="lastName" />
      <TextField style={setWidth(200)} source="email" />
      <SubscriptionsType label="Premium Exp" resorce="premiumExp" />
      <DateField
        label="Created At"
        source="createdAt"
        showTime
        style={centerText}
      />
      <ReferenceArrayField
        label="Native Languages"
        reference="languages"
        source="nativeLanguagesIds"
        style={centerText}
        sortable={false}
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        label="Study Languages"
        reference="languages"
        source="studyLanguagesIds"
        style={{ ...setWidth(200), ...centerText, display: "flex" }}
        sortable={false}
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <FunctionField
        label="Practice"
        render={(record) => (record.ready ? "Yes" : "")}
      />
      <ReferenceField
        label="Country"
        source="countryId"
        reference="countries"
        allowEmpty
        style={centerText}
        sortable={false}
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField
        style={setWidth(120)}
        label="Platform"
        source="registeredPlatform"
      />
      <FunctionField
        label="Url"
        render={(record) => (
          <a
            href={record.url}
            title={record.url}
            style={shortLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {record.url}
          </a>
        )}
      />
      <TextField
        style={setWidth(50)}
        label="Active"
        source="activeCardsCount"
        sortable={false}
      />
      <TextField
        style={setWidth(50)}
        label="Loaded"
        source="loadedCardsCount"
        sortable={false}
      />
      <TextField
        style={setWidth(50)}
        label="Studied"
        source="studiedCardsCount"
        sortable={false}
      />
      <EditButton style={{ ...setWidth(0), ...smallButton }} />
      <ConditionalDeleteButton
        {...props}
        style={{ ...setWidth(0), ...smallButton }}
      />
    </Datagrid>
  </List>
);

const CustomSelectType = (props) => {
  const [choices, setChoices] = useState([]);
  const [defaultValue] = useState(() => {
    const subscriptions = props?.record?.subscriptions;
    if (subscriptions.length) {
      props.setActivePremium(true);

      return subscriptions.find((item) => {
        const maxValue = new Date(
          Math.max(...subscriptions.map((e) => new Date(e.expirationDate)))
        ).toISOString();
        return item.expirationDate === maxValue;
      });
    } else {
      if (props?.record?.premium) {
        props.setActivePremium(true)
        return { type: 'USER_PREMIUM' }
      }

      props.setActivePremium(false);
      return { type: "FREE" };
    }
  });

  useEffect(() => {
    axios
      .get(`/${props.source}`)
      .then((res) => {
        setChoices(res.data);
      });
  }, [props.record.subscriptions, props.source]);

  const handleTogglePremium = (e) => {
    if (e.target.value === "FREE") {
      props.setActivePremium(false);
    } else {
      props.setActivePremium(true);
    }
  };

  return (
    <SelectInput
      {...props}
      choices={choices}
      defaultValue={defaultValue?.type}
      onChange={handleTogglePremium}
    />
  );
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton submitOnEnter={false} {...props} />
    <ConditionalDeleteButton {...props} style={{ ...rightElement }} />
  </Toolbar>
);

export const UserEdit = (props) => {
  const [activePremium, setActivePremium] = useState(false);
  return (
    <Edit {...props}>
      <SimpleForm validate={validateUser} toolbar={<CustomToolbar />}>
        <TextInput label="First Name" source="firstName" />
        <TextInput label="Last Name" source="lastName" />
        <TextInput source="email" />
        <BooleanInput label="Practice ready" source="ready" />
        <BooleanInput source="active" />
        <BooleanInput source="baned" />
        <ReferenceInput
          label="Country"
          source="countryId"
          reference="countries"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput
          label="Native Languages"
          reference="languages"
          source="nativeLanguagesIds"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="Study Languages"
          reference="languages"
          source="studyLanguagesIds"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <CustomSelectType
          label="Type Subscription"
          source="subscriptions/types"
          optionValue="id"
          setActivePremium={setActivePremium}
        />
        {activePremium && (
          <DateInput label="Premium Exp" source="premiumExp" validate={maxValue('2029-12-30')} required={true} />
        )}
      </SimpleForm>
    </Edit>
  );
};

export const UserCreate = (props) => {
  const [activePremium, setActivePremium] = useState(false);
  return (
    <Create {...props}>
      <SimpleForm validate={validateUser}>
        <TextInput label="First Name" source="firstName" />
        <TextInput label="Last Name" source="lastName" />
        <TextInput source="email" />
        <ReferenceInput
          label="Country"
          source="countryId"
          reference="countries"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <CustomSelectType
          label="Type Subscription"
          source="subscriptions/types"
          optionValue="id"
          setActivePremium={setActivePremium}
        />
        {activePremium && (
          <DateInput label="Premium Exp" source="premiumExp" required={true} />
        )}
        <ReferenceArrayInput
          label="Native Languages"
          reference="languages"
          source="nativeLanguagesIds"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="Study Languages"
          reference="languages"
          source="studyLanguagesIds"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
