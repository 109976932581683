import React from 'react';
import { 
 List, Edit, Datagrid,
 TextField, EditButton, DeleteButton,
 SimpleForm, UrlField
} from 'react-admin';

export const TeachersList = (props) => (
    <List {...props}>
        <Datagrid>
			<TextField source="#" />
            <TextField source="firstName" />
            <TextField source="lastName" />
			<TextField source="email" />
			<TextField source="premiumExp" />
			<TextField source="createdAt" />
			<TextField source="native_language" />
			<TextField source="country" />	
			<TextField source="teaching" />	
			<TextField source="language_school" />							
            <UrlField source="url" />		
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export const TeacherEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>	
            <TextField source="firstName" />
            <TextField source="lastName" />
			<TextField source="email" />	
			<TextField source="premiumExp" />
			<TextField source="native_language" />
			<TextField source="studied_language" />
			<TextField source="country" />		

        </SimpleForm>
    </Edit>
);
