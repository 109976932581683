import React from "react";

const iconStyle = {
    width: '32px'
};

const UsersIcon = () => {
    return (
        <svg style={iconStyle}  viewBox="0 0 48 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M32.2265 26.9945L31.8036 26.5716C31.7839 26.5519 31.7741 26.5421 31.7544 26.5224C29.4139 27.909 26.7194 28.6564 23.956 28.6564C20.4845 28.6564 17.1803 27.5058 14.4955 25.3817C13.7678 25.8832 13.0204 26.4437 12.2927 27.0436C11.7125 27.5157 11.3781 28.2336 11.3781 28.9809V32.5311H23.9953H36.6027V31.3608L32.8952 27.6533L32.2265 26.9945Z"
                fill="black"/>
            <path
                d="M24.556 5.3103H23.9954H23.4349C20.8583 5.3103 18.7637 7.40497 18.7637 9.98152V15.1641C18.7637 15.9803 19.1767 16.6884 19.8061 17.1113V21.5366C21.0747 22.1857 22.4908 22.5397 23.9659 22.5397C25.4607 22.5397 26.8965 22.1857 28.1749 21.517V17.1211C28.8043 16.6982 29.2174 15.9902 29.2174 15.1739V9.99135C29.2272 7.39514 27.1325 5.3103 24.556 5.3103Z"
                fill="black"/>
            <path
                d="M47.2329 23.5428C44.5384 21.3301 41.6373 19.8648 41.0473 19.5698C40.9784 19.5403 40.9391 19.4715 40.9391 19.3928V15.2034C41.4701 14.8494 41.8143 14.2495 41.8143 13.571V9.22429C41.8143 7.06078 40.0639 5.3103 37.9003 5.3103H37.4283H36.9956C38.3724 7.54265 39.1689 10.0995 39.2673 12.7646C39.3853 15.7345 38.6379 18.6454 37.1431 21.1629C37.1628 21.1826 37.1726 21.1924 37.1923 21.2121L37.389 21.4088L37.6152 21.635L38.2839 22.3037L44.145 28.1648H48V25.1851C48 24.5459 47.7148 23.946 47.2329 23.5428Z"
                fill="black"/>
            <path
                d="M44.637 31.4102L36.8975 23.6708L36.2288 23.002L36.0026 22.7759L35.8059 22.5792L35.2847 23.1004L34.1636 21.9793C38.6086 16.7377 38.3628 8.87039 33.4064 3.91399C30.8003 1.30794 27.3781 0 23.9656 0C20.5532 0 17.1309 1.30794 14.5249 3.91399C9.31276 9.12608 9.31276 17.5834 14.5249 22.7955C17.1309 25.4016 20.5532 26.7095 23.9656 26.7095C27.0339 26.7095 30.1021 25.6573 32.5902 23.5528L33.7112 24.6739L33.19 25.1951L33.6129 25.6179L34.2816 26.2866L42.0211 34.0261C42.385 34.39 42.857 34.567 43.329 34.567C43.8011 34.567 44.2731 34.39 44.637 34.0261C45.3549 33.3082 45.3549 32.138 44.637 31.4102C44.637 31.4201 44.637 31.4102 44.637 31.4102ZM31.8329 21.2319C29.6596 23.4052 26.8077 24.487 23.9656 24.487C21.1137 24.487 18.2717 23.4052 16.0983 21.2319C11.7516 16.8852 11.7516 9.84397 16.0983 5.49728C18.2717 3.32394 21.1236 2.24218 23.9656 2.24218C26.8175 2.24218 29.6596 3.32394 31.8329 5.49728C36.1796 9.83414 36.1796 16.8852 31.8329 21.2319Z"
                fill="black"/>
            <path
                d="M10.9552 5.3103H10.5717H10.0997C7.93615 5.3103 6.18567 7.06078 6.18567 9.22429V13.571C6.18567 14.2495 6.52987 14.8494 7.06091 15.2034V19.3928C7.06091 19.4714 7.02157 19.5304 6.95273 19.5698C6.36268 19.855 3.46162 21.3301 0.767063 23.5428C0.28519 23.946 0 24.5459 0 25.1752V28.155H9.55878C9.75546 27.1421 10.2865 26.2275 11.0929 25.5686C11.742 25.0376 12.4008 24.536 13.0499 24.0738C8.02465 18.9601 7.32643 11.1714 10.9552 5.3103Z"
                fill="black"/>
        </svg>
    )
};

const SchoolsIcon = () => {
    return (
        <svg style={iconStyle} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 64 64" aria-hidden="true" role="img" className="iconify iconify--emojione-monotone"
             preserveAspectRatio="xMidYMid meet">
            <path
                d="M14.188 29.656H8.563c-.775 0-1.406.631-1.406 1.406v3.75c0 .775.631 1.406 1.406 1.406h5.625c.775 0 1.406-.631 1.406-1.406v-3.75c0-.775-.631-1.406-1.406-1.406m-3.282 5.625H8.563a.47.47 0 0 1-.469-.469v-3.75a.47.47 0 0 1 .469-.469h2.344v4.688zm3.75-.468a.47.47 0 0 1-.469.469h-2.344v-4.688h2.344a.47.47 0 0 1 .469.469v3.75"
                fill="#000000"/>
            <path
                d="M14.188 39.5H8.563c-.775 0-1.406.631-1.406 1.406v3.75c0 .775.631 1.406 1.406 1.406h5.625c.775 0 1.406-.631 1.406-1.406v-3.75c0-.775-.631-1.406-1.406-1.406m-3.282 5.625H8.563a.47.47 0 0 1-.469-.469v-3.75a.47.47 0 0 1 .469-.469h2.344v4.688zm3.75-.469a.47.47 0 0 1-.469.469h-2.344v-4.688h2.344a.47.47 0 0 1 .469.469v3.75"
                fill="#000000"/>
            <path
                d="M14.188 48.875H8.563c-.775 0-1.406.631-1.406 1.406v3.75c0 .775.631 1.406 1.406 1.406h5.625c.775 0 1.406-.631 1.406-1.406v-3.75c0-.775-.631-1.406-1.406-1.406M10.906 54.5H8.563a.47.47 0 0 1-.469-.469v-3.75a.47.47 0 0 1 .469-.469h2.344V54.5zm3.75-.469a.47.47 0 0 1-.469.469h-2.344v-4.688h2.344a.47.47 0 0 1 .469.469v3.75"
                fill="#000000"/>
            <path
                d="M48.406 31.063v3.75c0 .775.631 1.406 1.406 1.406h5.625c.775 0 1.406-.631 1.406-1.406v-3.75c0-.775-.631-1.406-1.406-1.406h-5.625a1.407 1.407 0 0 0-1.406 1.406m4.688-.469h2.344a.47.47 0 0 1 .469.469v3.75a.47.47 0 0 1-.469.469h-2.344v-4.688m-.938 4.687h-2.344a.47.47 0 0 1-.469-.469v-3.75a.47.47 0 0 1 .469-.469h2.344v4.688"
                fill="#000000"/>
            <path
                d="M55.438 39.5h-5.625c-.775 0-1.406.631-1.406 1.406v3.75c0 .775.631 1.406 1.406 1.406h5.625c.775 0 1.406-.631 1.406-1.406v-3.75c0-.775-.631-1.406-1.406-1.406m-3.282 5.625h-2.344a.47.47 0 0 1-.469-.469v-3.75a.47.47 0 0 1 .469-.469h2.344v4.688m3.75-.469a.47.47 0 0 1-.469.469h-2.344v-4.688h2.344a.47.47 0 0 1 .469.469v3.75"
                fill="#000000"/>
            <path
                d="M55.438 48.875h-5.625c-.775 0-1.406.631-1.406 1.406v3.75c0 .775.631 1.406 1.406 1.406h5.625c.775 0 1.406-.631 1.406-1.406v-3.75c0-.775-.631-1.406-1.406-1.406M52.156 54.5h-2.344a.47.47 0 0 1-.469-.469v-3.75a.47.47 0 0 1 .469-.469h2.344V54.5m3.75-.469a.47.47 0 0 1-.469.469h-2.344v-4.688h2.344a.47.47 0 0 1 .469.469v3.75"
                fill="#000000"/>
            <path
                d="M22.499 36.348c-.527-.178-.76-.28-.76-.512c0-.188.194-.35.597-.35c.399 0 .691.102.854.172l.207-.663a2.778 2.778 0 0 0-1.044-.183c-.967 0-1.549.475-1.549 1.094c0 .527.443.862 1.124 1.072c.491.155.686.286.686.512c0 .237-.225.393-.65.393c-.394 0-.776-.112-1.025-.226l-.188.679c.23.113.692.221 1.16.221c1.123 0 1.651-.517 1.651-1.126c0-.512-.34-.846-1.063-1.083"
                fill="#000000"/>
            <path
                d="M26.276 35.492c.327 0 .589.063.776.134l.188-.651c-.163-.076-.527-.162-1.007-.162c-1.239 0-2.236.689-2.236 1.929c0 1.035.729 1.815 2.145 1.815c.498 0 .881-.08 1.051-.156l-.141-.641a2.59 2.59 0 0 1-.771.119c-.825 0-1.311-.459-1.311-1.186c.001-.808.572-1.201 1.306-1.201"
                fill="#000000"/>
            <path d="M30.254 36.268h-1.525v-1.396h-.928v3.631h.928v-1.52h1.525v1.52h.923v-3.631h-.923z" fill="#000000"/>
            <path
                d="M33.788 34.813c-1.208 0-1.991.813-1.991 1.901c0 1.035.71 1.849 1.924 1.849c1.197 0 2.01-.723 2.01-1.912c-.001-1.004-.686-1.838-1.943-1.838m-.017 3.098c-.62 0-.996-.502-.996-1.213c0-.706.364-1.234.989-1.234c.638 0 .99.561.99 1.213c0 .706-.359 1.234-.983 1.234"
                fill="#000000"/>
            <path
                d="M38.144 34.813c-1.209 0-1.992.813-1.992 1.901c0 1.035.71 1.849 1.924 1.849c1.198 0 2.011-.723 2.011-1.912c-.001-1.004-.688-1.838-1.943-1.838m-.019 3.098c-.62 0-.996-.502-.996-1.213c0-.706.364-1.234.989-1.234c.639 0 .99.561.99 1.213c0 .706-.358 1.234-.983 1.234"
                fill="#000000"/>
            <path d="M41.622 34.872h-.929v3.631h2.557v-.69h-1.628z" fill="#000000"/>
            <path
                d="M38.563 26.375a6.563 6.563 0 1 0-13.126 0a6.563 6.563 0 1 0 13.126 0m-11.25 0a4.689 4.689 0 1 1 9.378.002a4.689 4.689 0 0 1-9.378-.002"
                fill="#000000"/>
            <path
                d="M32.938 27.313v-3.75c0-.516-.422-.938-.938-.938s-.938.422-.938.938v2.813h-.938c-.516 0-.938.422-.938.938s.422.938.938.938H32a.943.943 0 0 0 .938-.939"
                fill="#000000"/>
            <path
                d="M61.063 58.25h-.938v-30h.634c1.055 0 1.53-.755 1.059-1.677l-3.077-6.021c-.472-.922-1.721-1.677-2.775-1.677H40.107l-7.17-5.804v-2.29c3.75 2.992 7.5-6.446 11.25-3.453c-3.75-5.294-7.5 1.841-11.25-3.453v-.937C32.938 2.422 32.516 2 32 2s-.938.422-.938.938v10.134l-7.17 5.804H8.035c-1.055 0-2.304.755-2.775 1.677l-3.077 6.021c-.472.922.004 1.677 1.059 1.677h.634v30h-.938a.94.94 0 0 0-.938.937v1.875a.94.94 0 0 0 .938.937h58.125a.94.94 0 0 0 .937-.937v-1.875a.94.94 0 0 0-.937-.938M17 58.25H5.75v-30h8.937c.018.163.05.318.1.459c.245.685.957 1.416 2.214 1.416V58.25zm25 2.594H22v-1.5h20v1.5M26.375 58.25V47h5.156v11.25h-5.156m6.094 0V47h5.156v11.25h-5.156m12.656 0H39.5V47h1.875v-1.881s-6.443-3.893-8.487-5.348a1.586 1.586 0 0 0-1.775 0c-2.044 1.455-8.487 5.348-8.487 5.348V47H24.5v11.25h-5.625v-30H17c-.516 0-.611-.269-.214-.596l14.49-11.934c.199-.163.462-.245.724-.245s.524.082.724.245l14.49 11.934c.397.327.302.596-.214.596h-1.875v30m13.125 0H47V30.125c1.257 0 1.969-.731 2.214-1.416c.05-.141.082-.296.1-.459h8.937v30z"
                fill="#000000"/>
            <path d="M29.246 52.156h1.348v.681h-1.348z" fill="#000000"/>
            <path d="M33.406 52.156h1.348v.681h-1.348z" fill="#000000"/>
        </svg>
    )
}

const CountriesIcon = () => {
    return (
        <svg style={iconStyle}  viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM22 39.86C14.1 38.88 8 32.16 8 24C8 22.76 8.16 21.58 8.42 20.42L18 30V32C18 34.2 19.8 36 22 36V39.86ZM35.8 34.78C35.28 33.16 33.8 32 32 32H30V26C30 24.9 29.1 24 28 24H16V20H20C21.1 20 22 19.1 22 18V14H26C28.2 14 30 12.2 30 10V9.18C35.86 11.56 40 17.3 40 24C40 28.16 38.4 31.94 35.8 34.78Z"
                fill="black"/>
        </svg>
    )
};
const LanguagesIcon = () => {
    return (
        <svg style={iconStyle}  viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M24 0C10.8 0 0 10.8 0 24C0 37.2 10.8 48 24 48C37.2 48 48 37.2 48 24C48 10.8 37.2 0 24 0ZM40.5602 14.3998H33.6002C32.8802 11.2798 31.6802 8.63977 30.2402 5.75977C34.5602 7.43977 38.4002 10.3198 40.5602 14.3998ZM24.0002 4.8C25.9202 7.68 27.6002 10.8 28.5602 14.4H19.4402C20.4002 11.04 22.0802 7.68 24.0002 4.8ZM5.52 28.8C5.04 27.36 4.8 25.68 4.8 24C4.8 22.32 5.04 20.64 5.52 19.2H13.68C13.44 20.88 13.44 22.32 13.44 24C13.44 25.68 13.68 27.12 13.68 28.8H5.52ZM7.44023 33.6H14.4002C15.1202 36.72 16.3202 39.36 17.7602 42.24C13.4402 40.56 9.60023 37.68 7.44023 33.6ZM14.4002 14.3998H7.44023C9.84023 10.3198 13.4402 7.43977 17.7602 5.75977C16.3202 8.63977 15.1202 11.2798 14.4002 14.3998ZM24.0002 43.2C22.0802 40.32 20.4002 37.2 19.4402 33.6H28.5602C27.6002 36.96 25.9202 40.32 24.0002 43.2ZM29.52 28.8H18.48C18.24 27.12 18 25.68 18 24C18 22.32 18.24 20.88 18.48 19.2H29.76C30 20.88 30.24 22.32 30.24 24C30.24 25.68 29.76 27.12 29.52 28.8ZM30.2402 42.24C31.6802 39.6 32.8802 36.72 33.6002 33.6H40.5602C38.4002 37.68 34.5602 40.56 30.2402 42.24ZM34.5598 28.8C34.7998 27.12 34.7998 25.68 34.7998 24C34.7998 22.32 34.5598 20.88 34.5598 19.2H42.7198C43.1998 20.64 43.4398 22.32 43.4398 24C43.4398 25.68 43.1998 27.36 42.7198 28.8H34.5598Z"
                  fill="black"/>
        </svg>
    )
};
const DictionariesIcon = () => {
    return (
        <svg style={iconStyle}  viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M25.74 30.14L20.66 25.12L20.72 25.06C24.2 21.18 26.68 16.72 28.14 12H34V8H20V4H16V8H2V11.98H24.34C23 15.84 20.88 19.5 18 22.7C16.14 20.64 14.6 18.38 13.38 16H9.38C10.84 19.26 12.84 22.34 15.34 25.12L5.16 35.16L8 38L18 28L24.22 34.22L25.74 30.14ZM37 20H33L24 44H28L30.24 38H39.74L42 44H46L37 20ZM31.76 34L35 25.34L38.24 34H31.76Z"
                fill="black"/>
        </svg>
    )
};
const TeachersIcon = () => {
    return (
        <svg style={iconStyle}  viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 26.36V34.36L24 42L38 34.36V26.36L24 34L10 26.36ZM24 6L2 18L24 30L42 20.18V34H46V18L24 6Z"
                  fill="black"/>
        </svg>
    )
};
const SubscriptionsIcon = () => {
    return (
        <svg style={iconStyle}  viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM26.82 36.18V40H21.48V36.14C18.06 35.42 15.16 33.22 14.94 29.34H18.86C19.06 31.44 20.5 33.08 24.16 33.08C28.08 33.08 28.96 31.12 28.96 29.9C28.96 28.24 28.08 26.68 23.62 25.62C18.66 24.42 15.26 22.38 15.26 18.28C15.26 14.84 18.04 12.6 21.48 11.86V8H26.82V11.9C30.54 12.8 32.4 15.62 32.52 18.68H28.6C28.5 16.46 27.32 14.94 24.16 14.94C21.16 14.94 19.36 16.3 19.36 18.22C19.36 19.9 20.66 21 24.7 22.04C28.74 23.08 33.06 24.82 33.06 29.86C33.04 33.52 30.3 35.52 26.82 36.18Z"
                fill="black"/>
        </svg>
    )
};
const PostsIcon = () => {
    return (
        <svg style={iconStyle} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M42 10C39.78 9.3 37.34 9 35 9C31.1 9 26.9 9.8 24 12C21.1 9.8 16.9 9 13 9C9.1 9 4.9 9.8 2 12V41.3C2 41.8 2.5 42.3 3 42.3C3.2 42.3 3.3 42.2 3.5 42.2C6.2 40.9 10.1 40 13 40C16.9 40 21.1 40.8 24 43C26.7 41.3 31.6 40 35 40C38.3 40 41.7 40.6 44.5 42.1C44.7 42.2 44.8 42.2 45 42.2C45.5 42.2 46 41.7 46 41.2V12C44.8 11.1 43.5 10.5 42 10ZM42 37C39.8 36.3 37.4 36 35 36C31.6 36 26.7 37.3 24 39V16C26.7 14.3 31.6 13 35 13C37.4 13 39.8 13.3 42 14V37Z"
                fill="black"/>
            <path
                d="M35 21C36.76 21 38.46 21.18 40 21.52V18.48C38.42 18.18 36.72 18 35 18C31.6 18 28.52 18.58 26 19.66V22.98C28.26 21.7 31.4 21 35 21Z"
                fill="black"/>
            <path
                d="M26 24.98V28.3C28.26 27.02 31.4 26.32 35 26.32C36.76 26.32 38.46 26.5 40 26.84V23.8C38.42 23.5 36.72 23.32 35 23.32C31.6 23.32 28.52 23.92 26 24.98Z"
                fill="black"/>
            <path
                d="M35 28.66C31.6 28.66 28.52 29.24 26 30.32V33.64C28.26 32.36 31.4 31.66 35 31.66C36.76 31.66 38.46 31.84 40 32.18V29.14C38.42 28.82 36.72 28.66 35 28.66Z"
                fill="black"/>
        </svg>
    )
};
export {
    PostsIcon,
    SubscriptionsIcon,
    TeachersIcon,
    DictionariesIcon,
    LanguagesIcon,
    CountriesIcon,
    UsersIcon,
    SchoolsIcon
}
