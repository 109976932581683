import {fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

const httpClient = async (url, options = {}) => {
    console.log(url, options)
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    let  result = await fetchUtils.fetchJson(url, options);
    if(url.includes("countries") || url.includes("languages")) {
        result = { ...result, json: result.json.sort((a,b) => {
            if(a.name > b.name) { return 1; }
            else if (a.name < b.name) { return -1; }
            else return 0;
        })};
    }
    return result;
};

const restClient = jsonServerProvider(process.env.REACT_APP_API_URL+"/api/v1/admin", httpClient);

export default restClient;
