import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {withStyles} from '@material-ui/core/styles';
import {fade} from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import {crudDelete, startUndoable, translate} from 'ra-core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import {Button} from 'react-admin';

const styles = (theme) => ({
    deleteButton: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent'
            }
        }
    }
});

const DeleteButtonWithConfirmation = (props) => {
    const [isDialogShown, showDialog] = useState(false)

    const handleClick = () => {
        showDialog(true);
    };

    const handleCloseClick = () => {
        showDialog(false);
    };


    const handleDelete = (event) => {
        event.preventDefault();
        const {dispatchCrudDelete, resource, record, basePath, redirect} = props;
        dispatchCrudDelete(resource, record.id, record, basePath, redirect);
    };

    const {classes = {}, className} = props;
    return (
        <Fragment>
            <Button onClick={handleClick}
                    className={classnames('ra-delete-button', classes.deleteButton, className)} key="button">
                <ActionDelete/>
            </Button>
            <Dialog fullWidth open={isDialogShown} onClose={handleCloseClick}>
                <DialogTitle style={{"color": "#707070"}}>Do you want to remove this item?</DialogTitle>
                <DialogActions>
                    <Button label="CANCEL" onClick={handleCloseClick} style={{"color": "#2261A1"}}/>
                    <Button onClick={handleDelete} label="CONFIRM" key="button"
                            style={{"margin-left": "auto", "margin-right": 0, "color": "#2261A1"}}/>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}


DeleteButtonWithConfirmation.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    dispatchCrudDelete: PropTypes.func.isRequired,
    label: PropTypes.string,
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
    resource: PropTypes.string.isRequired,
    startUndoable: PropTypes.func,
    translate: PropTypes.func,
    undoable: PropTypes.bool
};

DeleteButtonWithConfirmation.defaultProps = {
    redirect: 'list',
    undoable: true,
    fullWidth: false,
};

export default compose(
    connect(
        null,
        {startUndoable, dispatchCrudDelete: crudDelete}
    ),
    translate,
    withStyles(styles)
)(DeleteButtonWithConfirmation);
