import React from 'react';
import authClient from './authClient';
import restClient from './restClient';
import {Admin, defaultTheme, Resource} from 'react-admin';
import {UserCreate, UserEdit, UserList} from "./users";
import {SchoolEdit, SchoolsList} from './schools';
import {
    CountriesIcon,
    DictionariesIcon,
    LanguagesIcon,
    PostsIcon,
    SubscriptionsIcon,
    TeachersIcon,
    UsersIcon,
    SchoolsIcon
} from "./Icons";
import {LanguageCreate, LanguageEdit, LanguageList} from "./languages";
import {DictionariesList, DictionaryCreate, DictionaryEdit} from "./dictionaries";
import {TeacherEdit, TeachersList} from "./teachers";
import {SubscriptionCreate, SubscriptionEdit, SubscriptionsList} from "./subscriptions";
import {PostCreate, PostEdit, PostsList} from "./posts";
import { createMuiTheme } from "@material-ui/core/styles";
import closeSidebarSaga from "./closeSidebarSaga";
import { CountryEdit, CountryList} from "./countries";

const theme = createMuiTheme({...defaultTheme, sidebar: {width: 200, closedWidth: 75}});

const App = () => {
    return (
        <Admin customSagas={[closeSidebarSaga]} theme={theme} dataProvider={restClient} authProvider={authClient}>
            <Resource name="users"
                      list={UserList}
                      edit={UserEdit}
                      create={UserCreate}
                      icon={UsersIcon}
            />

            <Resource name="countries"
                      icon={CountriesIcon}
                      list={CountryList}
                      edit={CountryEdit}

            />

            <Resource name="languages"
                      list={LanguageList}
                      edit={LanguageEdit}
                      create={LanguageCreate}
                      icon={LanguagesIcon}
            />

            <Resource name="dictionaries"
                      list={DictionariesList}
                      edit={DictionaryEdit}
                      create={DictionaryCreate}
                      icon={DictionariesIcon}
            />

            <Resource name="schools"
                      list={SchoolsList}
                      edit={SchoolEdit}
                      icon={SchoolsIcon}
            />

            <Resource name="teachers"
                      list={TeachersList}
                      edit={TeacherEdit}
                      icon={TeachersIcon}
            />

            <Resource name="subscriptions"
                      list={SubscriptionsList}
                      edit={SubscriptionEdit}
                      create={SubscriptionCreate}
                      icon={SubscriptionsIcon}
            />

            <Resource name="posts"
                      list={PostsList}
                      edit={PostEdit}
                      create={PostCreate}
                      icon={PostsIcon}
            />
        </Admin>)
};

export default App;
