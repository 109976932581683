import React from "react";
import {
    Datagrid,
    DateField,
    FunctionField,
    List,
    TextField,
    Edit,
    EditButton,
    SimpleForm,
    NumberInput,
    NumberField,
    SelectInput,
    ReferenceInput,
    BooleanInput
} from "react-admin";
import { centerText, dataGrid, setWidth, shortLink, smallButton } from "./helper";
import { ConditionalDeleteButton } from "./ConditionalDeleteButton";
import { ConditionalBulkDeleteButton } from "./ConditionalBulkDeleteButton";
import { SchoolRequests } from './schoolRequests';

export const SchoolsList = (props) => (
    <List
        perPage={100}
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        bulkActionButtons={<ConditionalBulkDeleteButton />}
    >
        <Datagrid style={dataGrid}>
            <TextField
                style={setWidth("auto")}
                label="#"
                source="order"
                sortable={false}
            />
            <TextField style={setWidth(120)} label="School name" source="name" />
            <TextField style={setWidth(120)} label="Country" source="country" />
            <FunctionField
                label="Owner Url"
                render={(record) => (
                    <a
                        href={record.ownerUrl}
                        style={shortLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {record.ownerUrl}
                    </a>
                )}
            />
            <TextField style={setWidth(120)} label="Email" source="email" />
            <DateField
                label="Subscription Exp"
                source="premiumExp"
                showTime
                style={centerText}
                sortable={false}
            />
            <DateField
                label="Created At"
                source="createdAt"
                showTime
                style={centerText}
            />
            <FunctionField
                label="Web Site"
                render={(record) => (
                    <a
                        href={record.website}
                        title={record.website}
                        style={shortLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {record.website}
                    </a>
                )}
            />
            <FunctionField
                label="School Url"
                render={(record) => (
                    <a
                        href={record.schoolUrl}
                        style={shortLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {record.schoolUrl}
                    </a>
                )}
            />
            <TextField
                style={setWidth(50)}
                label="Teachers"
                source="teachers"
                sortable={false}
            />
            <TextField
                style={setWidth(50)}
                label="Students"
                source="students"
                sortable={false}
            />
            <TextField
                style={setWidth(50)}
                label="Lessons"
                source="lessons"
                sortable={false}
            />
            <FunctionField
                label="Requests"
                render={(record) => <SchoolRequests schoolId={record.id} requestsCount={record.requestsCount} />}
            />
            <NumberField
                style={setWidth(50)}
                label="Weight"
                source="weight"
                sortable={false}
            />
            <EditButton style={{ ...setWidth(0), ...smallButton }} />
            <ConditionalDeleteButton
                {...props}
                style={{ ...setWidth(0), ...smallButton }}
            />
        </Datagrid>
    </List>
);

export const SchoolEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextField label="School name" source="name"/>
                <NumberInput label="Weight" source="weight" min={0.00} step={0.01} format={(value) => parseFloat(value).toFixed(2)}/>
                <ReferenceInput
                    label="Promo country"
                    source="promoCountryId"
                    reference="countries"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput
                    allowEmpty
                    label="Promo Studied Language"
                    source="promoStudiedLangId"
                    reference="languages"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput
                    allowEmpty
                    label="Promo Native Language"
                    source="promoNativeLangId"
                    reference="languages"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <BooleanInput label="Active promotion" source="activePromotion"  />
            </SimpleForm>
        </Edit>
    );
};
