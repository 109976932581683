import React from 'react';
import { 
 List, Edit, Create, Datagrid, 
 TextField, EditButton, DeleteButton,
 SimpleForm, TextInput
} from 'react-admin';

export const SubscriptionsList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="#" />
            <TextField source="name" />
            <TextField source="price" />		
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export const SubscriptionEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>			
            <TextField source="title" />
            <TextField source="price" />
        </SimpleForm>
    </Edit>
);

export const SubscriptionCreate = (props) => (
    <Create {...props}>
        <SimpleForm>

            <TextInput source="name" />
            <TextInput source="price" />
	

        </SimpleForm>
    </Create>


);
