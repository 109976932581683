import React from 'react';
import { 
 List, Edit, Create, Datagrid, 
 TextField, EditButton, DeleteButton,
 SimpleForm, TextInput
} from 'react-admin';


export const PostsList = (props) => (
    <List {...props}>
        <Datagrid>
			<TextField source="#" />
			<TextField source="title" />
            <TextField source="texts" />
            <TextField source="key words" />

            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export const PostEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>	
            <TextField source="text" />		

        </SimpleForm>
    </Edit>
);

export const PostCreate = (props) => (
    <Create {...props}>
        <SimpleForm>

            <TextInput source="text" />
            <TextInput source="key words" />	
		    <TextInput source="h1" />
		    <TextInput source="h2" />
        </SimpleForm>
    </Create>


);

