
export const centerText = {textAlign: 'center'};
export const dataGrid = {
    tableLayout: "auto",
    width: "100%",
};
export const smallButton = {
    "font-size": 0,
    "min-width": "30px",
};

export const shortLink = {
    display: 'block',
    width: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
};
export const setWidth = width => ({
    display: 'block',
    'max-width': width + 'px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: "none",
});

export const rightElement = {
    'margin-left': 'auto !important',
    'margin-right': '0px !important'
};
