import React from 'react';
import {
 List, Edit, Create, Datagrid,
 TextField, EditButton, DeleteButton,
 SimpleForm, TextInput,ReferenceField
} from 'react-admin';

export const LanguageList = (props) => (
    <List {...props} pagination={<div></div>}>
        <Datagrid>
            <TextField label="#" source="order" />
            <TextField label="Language" source="name" />
            {/*<TextField label="ISO 639-1" source="iso2" />*/}
            {/*<TextField label="ISO 639-2 T" source="iso3" />*/}
            <TextField label="Leaners" source="studyUserCount" />
            <TextField label="Language practice" source="studyReadyUserCount" />
            <TextField label="Native Speakers" source="nativeUserCount" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export const LanguageEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput label="ISO 639-1" source="iso2" />
            <TextInput label="ISO 639-2 Т" source="iso3" />
        </SimpleForm>
    </Edit>
);

export const LanguageCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
			<TextInput source="name" />
            <TextInput label="ISO 639-1" source="iso2" />
            <TextInput label="ISO 639-2 Т" source="iso3" />
        </SimpleForm>
    </Create>
);


const centerCell = {
    textAlign: "center",
    width: "100%",
    display: "inline-block"
};
