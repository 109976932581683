import React from 'react';

import { 
 List, Edit, Create, Datagrid, 
 TextField, EditButton, DeleteButton,
 SimpleForm, TextInput
} from 'react-admin';

export const DictionariesList = (props) => (
    <List {...props} title="Knowledge base">
        <Datagrid>
            <TextField source="#" />
            <TextField source="title" />
            <TextField source="price" />		
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export const DictionaryEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>			
            <TextField source="title" />
            <TextField source="price" />
        </SimpleForm>
    </Edit>
);

export const DictionaryCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
			<TextInput source="title" />
            <TextInput source="price" />

        </SimpleForm>
    </Create>
);
