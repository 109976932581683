import axios from "axios";

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`
    config.headers.Accept = 'application/json';
    config.baseURL = `${process.env.REACT_APP_API_URL}/api/v1/admin`

    return config;
});

export const request = axios;