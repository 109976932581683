import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DataGrid } from '@material-ui/data-grid';
import { request } from './request';
import { shortLink } from "./helper";
import moment from 'moment';

async function loadServerRows(id, page, pageSize) {
  const response = await request({
    method: 'get',
    url: `/schools/${id}/requests`,
    params: {
      _start: page * pageSize,
      _end: (page + 1) * pageSize,
      _sort: 'createdAt',
      _order: 'DESC'
    }
  });

  if (response.data) {
    return response.data.
      sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate))
      .map((request, index) => {
        return {
          ...request,
          id: index + page * pageSize + 1
        }
      })
  }

  return [];
}

export function SchoolRequests(params) {
  const schoolId = params.schoolId;
  const columns = [
    {
      field: 'id',
      headerName: '#',
      type: 'number',
      width: 70
    },
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      width: 200
    },
    {
      field: 'requestDate',
      headerName: 'RequestDate',
      type: 'dateTime',
      width: 200,
      valueFormatter: ({ value }) => moment(value).format("MM/DD/YYYY hh:mm A"),
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      width: 120
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'string',
      width: 200
    },
    {
      field: 'phone',
      headerName: 'Phone',
      type: 'string',
      width: 130
    },
    {
      field: 'studentUrl',
      headerName: 'StudentUrl',
      width: 200,
      renderCell: ({ value }) => {
        return <a
          href={value}
          style={shortLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </a>
      }
    }
  ];
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(100);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  async function fillRows(schoolId, page, pageSize) {
    setLoading(true);
    const newRows = await loadServerRows(schoolId, page, pageSize);
    setRows(newRows);
    setLoading(false);
  }

  const handleClickOpen = async () => {
    setOpen(true);
    await fillRows(schoolId, page, pageSize);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onPageChange = async (newPage) => {
    setPage(newPage);
    await fillRows(schoolId, newPage, pageSize);
  };

  const onPageSizeChange = async (newPageSize) => {
    setPageSize(newPageSize);
    await fillRows(schoolId, page, newPageSize);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        color="primary"
      >
        {params.requestsCount}
      </Button>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>School Requests</DialogTitle>
        <DialogContent>
          <div style={{ height: 1000, width: 1100 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pagination
              paginationMode="server"
              pageSize={pageSize}
              rowCount={params.requestsCount}
              onPageSizeChange={onPageSizeChange}
              rowsPerPageOptions={[10, 20, 100]}
              onPageChange={onPageChange}
              loading={loading}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
